'use client';

import type { ReactNode } from 'react';
import { useUserStore } from '@/store/mutable-data/user/UserStoreContext';

const GuestsOnlyView = ({ children }: { children: ReactNode }) => {
    const { user } = useUserStore();
    const loggedIn = Boolean(user.id);

    if (loggedIn) {
        return null;
    }

    return children;
};

export default GuestsOnlyView;
