'use client';

import { Button, Link } from '@repo/ui';
import { usePathname } from 'next/navigation';
import { useUserStore } from '@/store/mutable-data/user/UserStoreContext';

const HomePageHeaderLeftSideButton = () => {
    const { user } = useUserStore();
    const loggedIn = Boolean(user.id);

    const asPath = usePathname();

    if (loggedIn) {
        return (
            <Link to="/catalog">
                <Button variant="cta">Explore all courses</Button>
            </Link>
        );
    }

    return (
        <Link to={`/register?next=${asPath}`}>
            <Button variant="cta">Start Learning for Free</Button>
        </Link>
    );
};

export default HomePageHeaderLeftSideButton;
