'use client';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { FeaturedImage, Box, Typography, RegisterForm } from '@repo/ui';
import Image from 'next/image';
import { useUserStore } from '@/store/mutable-data/user/UserStoreContext';

const HomePageHeaderRightSide = () => {
    const { user } = useUserStore();
    const loggedIn = Boolean(user.id);

    if (loggedIn) {
        return (
            <FeaturedImage
                Image={Image}
                src="/favicon.png"
                alt="logo"
                lazyLoad={false}
                quality={100}
                width={500}
                height={500}
            />
        );
    }

    return (
        <Box
            width="100%"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                background: 'rgba(0,0,0,.39)',
                padding: '1rem 1rem 0 1rem',
                borderRadius: '10px',
            }}
        >
            <Box
                backgroundColor="var(--secondary-color)"
                style={{ borderRadius: '50%' }}
                display="flex"
                alignItems="center"
                padding="0.5rem"
                margin="0 0 0.5rem 0"
            >
                <LockOutlinedIcon />
            </Box>

            <Typography padding="1rem 0" fontSize="1.5rem !important">
                Create Your Free Account
            </Typography>

            <RegisterForm autoFocus={false} withoutReload buttonText="Start Learning for Free" />
        </Box>
    );
};

export default HomePageHeaderRightSide;
