'use client';

import { Button } from '@repo/ui';
import { useUserStore } from '@/store/mutable-data/user/UserStoreContext';
import RedirectBackLinkWrapper from '@/components/molecules/RedirectBackLinkWrapper';

export default function RegisterActionButton({
    marginTop = 16,
    mainButton = false,
    text = 'Sign Up',
    nextUrl,
}: {
    marginTop?: number;
    mainButton?: boolean;
    text?: string;
    nextUrl?: string;
}) {
    const { user } = useUserStore();

    if (user.id) {
        return null;
    }

    const variant = mainButton ? 'cta' : undefined;
    return (
        <RedirectBackLinkWrapper url="/register" style={{ marginTop }} nextUrl={nextUrl}>
            <Button variant={variant}>{text}</Button>
        </RedirectBackLinkWrapper>
    );
}
